import React from 'react'
// import '../../style/Project.css'
import '../../style/GrowYourBusiness.css'
import { Link } from 'react-router-dom'


const GrowYourBusness = () => {
    return (
        <div className='grow_business'>
            <div className="container">
                <div className='business_Info'>
                    <h4>Ready To Grow Your Business?</h4>
                    <p>Just take a step ahead and contact us today or get a free quote to discuss how we can help you.</p>
                    <div className='book'>
                        <button className='btn'>
                            <Link to="/contactUsPage" style={{ color: "#fff" }}>
                                Book an Appointment
                            </Link>
                        </button>
                        <p>or</p>
                        <button className='btn'>Call : +8801737316384</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default GrowYourBusness