import React from 'react'
import {createBrowserRouter} from 'react-router-dom'
import Main from '../Layout/Main'
import Blog from '../components/Blog/Blog'
import Home from '../components/Home/Home'
import ServicePage from '../components/ServicePage/ServicePage'
import ContactUs from '../components/ContactUs/ContactUs'
import ContactUsPage from '../components/ContactUsFullPage/ContactUsFullPage'
import ContactUsFullPage from '../components/ContactUsFullPage/ContactUsFullPage'
import ProjectPage from '../components/ProjectPage/ProjectPage'
import ReadMore from '../components/SliderProject/ReadMore'
import PricingPage from '../components/PricingPage/PricingPage'
import Pricing from '../components/Sections/Pricing'
import BlogPage from '../components/BlogPage/BlogPage'
import BlogPageTwo from '../components/BlogPageTwo/BlogPageTwo'
import ServicePageDetails from '../components/ServicePageDetails/ServicePageDetails'
import AboutUs from '../components/AboutUs/AboutUs'

export const router = createBrowserRouter([
    {
        path : '/',
        element : <Main></Main>,
        children : [
            {
                path : '/',
                element : <Home></Home>
            },
            // {
            //     path : '/home',
            //     element : <Home></Home>
            // },
            {
                path : '/services',
                element : <ServicePage></ServicePage>
            },
            {
                path : '/projects',
                element: <ProjectPage></ProjectPage>
            },
            {
                path : '/blog',
                element : <BlogPageTwo></BlogPageTwo>
            },
            {
                path : '/pricing',
                element : <PricingPage></PricingPage>
            },
            {
                path : '/contact',
                element : <ContactUs></ContactUs>
            },
            {
                path : '/contact-us',
                element : <ContactUsFullPage></ContactUsFullPage>
            },
            {
                path : "/about-us",
                element: <AboutUs></AboutUs>
            },
            {
                path : '/read-more',
                element : <ReadMore></ReadMore>
            },
            {
                path : '/serviceDetails',
                element: <ServicePageDetails></ServicePageDetails>
            }
        ]
    },
])