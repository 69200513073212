import React, { useEffect }  from 'react'
import img1 from '../../assets/svg/devzet.svg'
import img2 from '../../assets/img/extreme_logo.jpeg'
import img3 from '../../assets/img/examtice_logo_dark.jpg'
import img4 from '../../assets/svg/savers.png'
import img5 from '../../assets/svg/eur.png'
import img6 from '../../assets/svg/mhec.png'
import img7 from '../../assets/svg/designWaver.svg'
import img8 from '../../assets/svg/Logo-white-name-E-1.png'
import img9 from '../../assets/svg/sma-shop.png'
import img10 from '../../assets/svg/bluegrass.svg'
import img11 from '../../assets/svg/p11.svg'
import img12 from '../../assets/svg/p12.svg'
import Projects from '../Sections/Projects'
import { Helmet } from 'react-helmet'

const ProjectPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>

            <Helmet>
                <title>Project -Web Arts Factory </title>
            </Helmet>

            <div className='servicePage-section'>
                <div className='servicePage-title'>
                    <h1>Projects We Completed</h1>
                    <p>Bringing Your Ideas to Life with Top-notch Solutions. Explore a World of Possibilities with Us!</p>
                    <div>
                        <button className='book-call-btn'>Book a Call</button>
                    </div>
                </div>
            </div>

            <div className='project-page-section'>
                <div className="container">
                    <p className='agency-title'>Capital Numbers is the preferred choice of Fortune 500 Firms, SMEs, Agencies and Satrtups to access India's top 1% software developers.</p>
                    <div className='agency-img'>
                        <img style={{ width: "150px" }} src={img1} alt="" />
                        <img style={{ width: "150px" }} src={img2} alt="" />
                        <img style={{ width: "150px" }} src={img3} alt="" />
                        <img style={{ width: "150px" }} src={img4} alt="" />
                        <img style={{ width: "150px" }} src={img5} alt="" />
                        <img style={{ width: "150px" }} src={img6} alt="" />
                        <img style={{ width: "150px" }} src={img7} alt="" />
                        <img style={{ width: "150px" }} src={img8} alt="" />
                        <img style={{ width: "150px" }} src={img9} alt="" />
                        <img style={{ width: "150px" }} src={img10} alt="" />
                        <img style={{ width: "150px" }} src={img11} alt="" />
                        <img style={{ width: "150px" }} src={img12} alt="" />
                    </div>
                </div>
            </div>

            <div>
                <Projects></Projects>
            </div>
        </div>
    )
}

export default ProjectPage