import React, { useEffect, useState } from 'react'
import icon from '../../assets/img/cs-icon6.png'
import icon1 from '../../assets/img/service-1.svg'
import icon5 from '../../assets/img/service-5.svg'
import icon9 from '../../assets/img/service-9.svg'
import icon6 from '../../assets/img/service-6.svg'
import icon7 from '../../assets/img/service-7.svg'
import icon8 from '../../assets/img/service-8.svg'
import icon10 from '../../assets/img/service-10.svg'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const ServicePage = () => {


    const slides = [
        {
            image: "https://www.capitalnumbers.com/images/webp/case-studies-slider/cs-slider-124.webp",
            title: "Django CRM Upgrades & Maintenance for a Leading Auto Parts Distributor",
            tech: 'Tecth Stack:  Django, JavaScript, Vue.js, MySQL',
            description: "We upgraded a Django CRM dashboard for an auto-parts distributor. Because of our solutions, the client can manage his retail stores and activities better.",
            readMore: "Read More",
        },
        {
            image: "https://www.capitalnumbers.com/images/webp/case-studies-slider/cs-slider-122.webp",
            title: "End-to-end Data Engineering Work for a Leading Nonprofit",
            tech: 'Tecth Stack: Java, Spring Boot, MongoDB, Oracle, Angular',
            description: "Capital Numbers did the end-to-end data engineering work for a product review platform. We performed data extraction, transformation, and loading to visualization and helped the client save his costs and bandwidth.",
            readMore: "Read More",
        },
        {
            image: "https://www.capitalnumbers.com/images/webp/case-studies-slider/cs-slider-120.webp",
            title: "UI/UX Development of SaaS App for Data Science",
            tech: 'Tecth Stack:  React JS, Redux Toolkit, TypeScript, Storybook',
            description: "We created a SaaS-based data science app that companies can use to store huge data lists like mails, bills, invoices, CRMs, etc., and use it for future analytics.",
            readMore: "Read More",
        },
    ];

    const interval = 5000;
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, interval);

        return () => {
            clearInterval(intervalId);
        };
    }, [slides.length, interval]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return (
        <div className='servicePage'>

            <Helmet>
                <title>Service -Web Arts Factory </title>
            </Helmet>

            <div className="">
                <div className='servicePage-section'>
                    <div className='servicePage-title'>
                        <h1>Software Development Services</h1>
                        <p>As a top software development company, Capital Numbers leverages advanced tech stacks to design, develop, deploy, and launch custom apps and UI/UX designs that are profitable and serve global clients. Talk to us to learn more!</p>
                        <div>
                            <button className='book-call-btn'>Book a Call</button>
                        </div>
                    </div>
                </div>
                <div className='our-software-service'>
                    <div className="container">
                        <div className='section-width'>
                            <h2 className='title'>Our Software Application Development Services</h2>
                            <p className='subTitle'>Bespoke mobile apps or web apps. eCommerce or chatbots. Product development or software design services. Dedicated hiring or a fixed quote. One-time or ongoing. We offer everything to cater to the unique needs of our clients.</p>
                        </div>

                        <div className='servicepage-developer'>

                            <div className='development-service development-service-height'>
                                <img src={icon} alt="" />
                                <h4>Web Application Development</h4>
                                <p>We have decade-long experience building best-in-class B2B and B2C web apps that power modern businesses and workflows.</p>
                                <div>
                                    <Link to='/read-more'><button className='learn-more-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div className='development-service product-development'>
                                <img src={icon} alt="" />
                                <h4>Outsourced Product Development</h4>
                                <p>Benefit from our product-building expertise and high-class engineering that get you the results without letting you struggle or put your mind to them.</p>
                                <div>
                                    <Link to='/read-more'><button className='learn-more-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div className='development-service development-service-height'>
                                <img src={icon1} alt="" />
                                <h4>DevOps</h4>
                                <p>Get in touch with our ace DevOps engineers to plan, build, and test your products and ship them faster to any cloud or on-premise</p>
                                <div>
                                    <Link to='/read-more'><button className='learn-more-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div className='development-service development-service-height development-service-fit software-development'>
                                <img src={icon5} alt="" />
                                <h4>Custom Software Development</h4>
                                <p>Our customized software solutions are unique to individual needs, budgets, timelines, and target groups. and target groups. and target groups.</p>
                                <div>
                                    <Link to='/read-more'><button className='learn-more-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div className='development-service product-development'>
                                <img src={icon6} alt="" />
                                <h4>Mobile Application Development</h4>
                                <p>Leave your mobile app development tasks to us because we’ve been in the business long enough to create.</p>
                                <div>
                                    <Link to='/read-more'><button className='learn-more-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div className='development-service development-service-height development-service-fit software-development'>
                                <img src={icon7} alt="" />
                                <h4>Dedicated Development Team</h4>
                                <p>Spreading yourself too thin? Let our dedicated team handle all your development needs so that you can put your energy to good use with, ou can put your energy to good use with</p>
                                <div>
                                    <Link to='/read-more'><button className='learn-more-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div className='development-service development-service-height development-service-fit2'>
                                <img src={icon8} alt="" />
                                <h4>Enterprise Content Management</h4>
                                <p>Get direct access to our CMS experts who don’t just excel at creating beautiful websites from scratch </p>
                                <div>
                                    <Link to='/read-more'><button className='learn-more-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div className='development-service product-development'>
                                <img src={icon9} alt="" />
                                <h4>Testing & QA</h4>
                                <p>What’s the point of a timely release if it’s full of bugs? We help you avoid such a case by. blockchain journey and even allow</p>
                                <div>
                                    <Link to='/read-more'><button className='learn-more-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div className='development-service development-service-height development-service-fit2'>
                                <img src={icon10} alt="" />
                                <h4>Blockchain</h4>
                                <p>Hire expert Blockchain developers from us to tap into expertise that can get you started with your blockchain journey and even allow. </p>
                                <div>
                                    <Link to='/read-more'><button className='learn-more-btn'>Learn More</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className='book-call'>
                            <button className='book-call-btn'>Book a Call</button>
                        </div>
                    </div>
                </div>


                <div>
                    <div className="slider">
                        <div className="section-width">
                            <h2 className="title slider-title">Case Studies</h2>
                        </div>
                        <div className="slider-info">
                            <div className="slide-project">
                                <div className="slide-content">
                                    <h2 className="slider-title">{slides[currentSlide].title}</h2>
                                    <p className="slider-tech">{slides[currentSlide].tech}</p>
                                    <p className="slider-des">{slides[currentSlide].description}</p>
                                    {slides[currentSlide].readMore && (
                                        <Link to='/read-more'>
                                            <button className="slider-button">
                                                {slides[currentSlide].readMore}
                                                <FaArrowRight className="slider-right-arrow"></FaArrowRight>
                                            </button>
                                        </Link>
                                    )}
                                </div>
                                <img src={slides[currentSlide].image} alt={`Slide ${currentSlide + 1}`} />
                            </div>

                            {/* <div className="slider-buttons">
                    <button onClick={handlePrev}>Previous</button>
                    <button onClick={handleNext}>Next</button>
                </div> */}

                            <div className="slider-dots">
                                {slides.map((slide, index) => (
                                    <span
                                        key={index}
                                        className={`dot ${index === currentSlide ? "active" : ""}`}
                                        onClick={() => setCurrentSlide(index)}
                                    ></span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicePage