import React from 'react'
import TopNavbar from '../components/Nav/TopNavbar'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer/Footer'
// import Header from '../components/Sections/Header'
// import Services from '../components/Sections/Services'
// import Projects from '../components/Sections/Projects'
// import Blog from '../components/Sections/Blog'
// import Pricing from '../components/Sections/Pricing'
// import Contact from '../components/Sections/Contact'
// import Home from '../components/Home/Home'

const Main = () => {
  return (
    <div>
        <TopNavbar></TopNavbar>
        <Outlet></Outlet>
        <Footer></Footer>
    </div>
  )
}

export default Main