import React from 'react'
import serviceDetailsImg from '../../assets/img/servicePage Details.jpg'
import bestDesing from '../../assets/img/Best-Design.png'
import fullyDynamic from '../../assets/img/Fully-Dynamic.png'
import liveChat from '../../assets/img/Live-Chat.png'
import mobileFriendly from '../../assets/img/Mobile-Friendly.png'
import responsive from '../../assets/img/Responsiveness.png'
import seoOptimize from '../../assets/img/SEO-Optimized.png'
import pacakge1 from '../../assets/img/Website-Design-and-Development-Package-1.png'
import pacakge2 from '../../assets/img/Website-Design-and-Development-Package-2.png'
import pacakge3 from '../../assets/img/Website-Design-and-Development-Package-3.png'
import pacakge4 from '../../assets/img/Website-Design-and-Development-Package-4.png'
import pacakge5 from '../../assets/img/Website-Design-and-Development-Package-5.png'
import pacakge6 from '../../assets/img/Website-Design-and-Development-Package-6.png'
import pacakge7 from '../../assets/img/Website-Design-and-Development-Package-7.png'
import pacakge8 from '../../assets/img/Website-Design-and-Development-Package-8.png'

const ServicePageDetails = () => {
    return (
        <div className='servicePageDetails'>
            <div>
                <img className='serviceDetailsImg' src={serviceDetailsImg} alt="" />
            </div>

            <div className='our-service'>
                <div>
                    <img src={bestDesing} alt="" />
                    <h4>Best Desing</h4>
                </div>
                <div>
                    <img src={fullyDynamic} alt="" />
                    <h4>Fully Dynamic</h4>
                </div>
                <div>
                    <img src={liveChat } alt="" />
                    <h4>Live Chat</h4>
                </div>
                <div>
                    <img src={mobileFriendly} alt="" />
                    <h4>Mobile Friendly</h4>
                </div>
                <div>
                    <img src={responsive} alt="" />
                    <h4>Responsive</h4>
                </div>
                <div>
                    <img src={seoOptimize} alt="" />
                    <h4>SEO Optimize</h4>
                </div>
            </div>

            <div className='serviceDetailsPackages'>

                <img src={pacakge1} alt="" />
                <img src={pacakge2} alt="" />
                <img src={pacakge3} alt="" />
                <img src={pacakge4} alt="" />
                <img src={pacakge5} alt="" />
                <img src={pacakge6} alt="" />
                <img src={pacakge7} alt="" />
                <img src={pacakge8} alt="" />

            </div>
        </div>

    )
}

export default ServicePageDetails