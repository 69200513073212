import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import img1 from '../../assets/img/slider1.png'
import img2 from '../../assets/img/slider2.png'
import img3 from '../../assets/img/slider3.png'


const Slider = () => {
    return (
        <div>
            <Carousel showArrows={true} autoPlay={true} interval={2000} infiniteLoop={true}>
                <div style={{height: "500px"}}>
                    <img src={img1} />
                </div>
                <div style={{height: "500px"}}>
                    <img src={img2} />
                </div>
                <div style={{height: "500px"}}>
                    <img src={img3} />
                </div>
            </Carousel>
        </div>
    )
}

export default Slider