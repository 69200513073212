import React, { useEffect, useState } from 'react'

const CustomSoftwareSolutions = () => {

    return (
        <div>

            <div className='custom-solutions'>
                <div className='container'>
                    <div className='custom-solutions-des'>
                        <h3>Digital Dreams Delivered by Webartsfactory, a sister concern of Morsheda PUC Ltd</h3>
                        <p>Meet the driving force behind Webartsfactory, a sister concern of Morsheda PUC Ltd. A team of digital enthusiasts, creative minds, and tech experts, dedicated to shaping your online success story. Discover innovation, expertise, and a passion for all things digital in our journey to redefine the digital landscape.</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CustomSoftwareSolutions