import React from 'react'
import ContactUs from '../ContactUs/ContactUs'
import brand from '../../assets/img/Bread-BG.fd2fc0f.png'

import award1 from '../../assets/svg/a1.svg'
import award2 from '../../assets/svg/a2.svg'
import award3 from '../../assets/svg/a3.svg'
import award4 from '../../assets/svg/a4.svg'
import award5 from '../../assets/svg/a5.svg'
import award6 from '../../assets/svg/a6.svg'
import award7 from '../../assets/svg/a7.svg'
import award8 from '../../assets/svg/a8.svg'
import award9 from '../../assets/svg/a9.svg'
import award10 from '../../assets/svg/a10.svg'

const PricingPage = () => {
    return (
        <div>
            <div className='price-brand-img'>
                <h2 className='brand-title'>Link Building</h2>
                <p className='brand-info'>Lorem Ipsum is simply dummy.</p>
            </div>

            <div className="">
                <div className='pricing'>
                    <div className="container">
                        <div className='pricing-title'>
                            <h1 className="pricing-subTitle">We Have Pricing for All Types of Businesses</h1>
                            <p className="font20">
                                We can cover all the aspects of your digital marketing
                            </p>
                        </div>

                        <div className='pricing-package'>
                            <div className='single-package bg-green'>
                                <div className='price price-basic'>
                                    <p className='price-title'>Basic</p>
                                    <h2 className='total-price'>$29</h2>
                                </div>
                                <ul className='package-details'>
                                    <li className='package-details-info'>31 Full User</li>
                                    <li className='package-details-info'>1,000 Email Previews</li>
                                    <li className='package-details-info'>5 Contact per client</li>
                                    <li className='package-details-info'>5 Coffe Cups</li>
                                </ul>
                                <div className='buy-now'>
                                    <button className='buy-now-button'>Buy Now</button>
                                </div>
                            </div>
                            <div className='single-package bg-red'>
                                <div className='price price-stander'>
                                    <p className='price-title'>Stander</p>
                                    <h2 className='total-price'>$59</h2>
                                </div>
                                <ul className='package-details'>
                                    <li className='package-details-info'>31 Full User</li>
                                    <li className='package-details-info'>1,000 Email Previews</li>
                                    <li className='package-details-info'>5 Contact per client</li>
                                    <li className='package-details-info'>5 Coffe Cups</li>
                                </ul>
                                <div className='buy-now'>
                                    <button className='buy-now-button'>Buy Now</button>
                                </div>
                            </div>
                            <div className='single-package bg-blue'>
                                <div className='price price-primary'>
                                    <p className='price-title'>Primary</p>
                                    <h2 className='total-price'>$119</h2>
                                </div>
                                <ul className='package-details'>
                                    <li className='package-details-info'>31 Full User</li>
                                    <li className='package-details-info'>1,000 Email Previews</li>
                                    <li className='package-details-info'>5 Contact per client</li>
                                    <li className='package-details-info'>5 Coffe Cups</li>
                                </ul>
                                <div className='buy-now'>
                                    <button className='buy-now-button'>Buy Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='recent-award'>
                        <div className="container">
                            <h3 className='title'>Recent Awards & Certifications</h3>
                            <div className='award'>
                                <img className='award-image' src={award1} alt="" />
                                <img className='award-image' src={award2} alt="" />
                                <img className='award-image' src={award3} alt="" />
                                <img className='award-image' src={award4} alt="" />
                                <img className='award-image' src={award5} alt="" />
                                <img className='award-image' src={award6} alt="" />
                                <img className='award-image' src={award7} alt="" />
                                <img className='award-image' src={award8} alt="" />
                                <img className='award-image' src={award9} alt="" />
                                <img className='award-image' src={award10} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <ContactUs></ContactUs>
            </div>
        </div>
    )
}

export default PricingPage