import React, { useEffect }  from 'react'
import aboutUs from '../../assets/img/aboutUs.jpg'
import ceo from '../../assets/img/ceo.png'
import co_ceo from '../../assets/img/co-ceo.png'
import backendDeveloper from '../../assets/img/backend-developer.png'
import backendSabron from '../../assets/img/backendDeveloper.png'
import mern_developer from '../../assets/img/mern-developer.png'
import feroj from '../../assets/img/feroj_vai.jpg'
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import { Helmet } from 'react-helmet'

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className='aboutUs'>

            <Helmet>
                <title>About Us -Web Arts Factory </title>
            </Helmet>

            <div className="aboutUs-bannar">
                <div className="container">
                    <div style={{ textAlign: "center", marginBottom: "30px" }}>
                        <span className='about-title text-center'>About Us</span>
                    </div>
                    <div>
                        <h4>Software Development Partner for Global Enterprises, Small Businesses and Award Winning Startups</h4>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className='our-story'>
                    <div className='story-img'>
                        <img src={aboutUs} alt="" />
                    </div>

                    <div className='story-content'>
                        <h2>Our Story</h2>
                        <p>
                            WebArtsFactory was founded in 2020 by our two co-founders who sought to create a software and custom application development company that offers technical solutions for real-world business problems.
                        </p>

                        <p>
                            While many companies at that time did just that, our founders committed to serving clients better, knowing that talent, transparency, good work ethics, and honesty make a company great, but only when employees are empowered, respected, and cared for.
                        </p>

                        <p>
                            That assumption allowed the company to deliver custom development projects for many countries, growing to over many employees.
                        </p>
                    </div>
                </div>


            </div>

            <div className='team'>
                <div className="container">
                    <div className='team-title'>
                        <h3>Leadership Team</h3>
                        <p>Everyone in our leadership team has been with the organization for over ten years. They built their own teams and know the trade better than anyone. Our company leaders are executive sponsors in our client relationships and are prominent experts in technology, finance, and offshore delivery.</p>
                    </div>

                    <div className='team-member'>
                        <div className='member'>
                            <div className='member-img'>
                                <img src={ceo} alt="" />
                            </div>
                            <div className='member-intro'>
                                <h4>Abdullah Al Fouad Emran </h4>
                                <p>Founder</p>
                            </div>
                            <div className='member-social'>
                                <a href="https://www.facebook.com/abdullah.fouad.18" target='_blank'>
                                    <FaFacebookSquare />
                                </a>
                                <a href="https://www.linkedin.com/in/abdullah-al-fouad-emran-17422047/" target='_blank'>
                                    <FaLinkedin />
                                </a>
                            </div>
                        </div>


                        {/* <div className='member'>
                            <div className='member-img'>
                                <img src={co_ceo} alt="" />
                            </div>
                            <div className='member-intro'>
                                <h4>Md. Mazaharul Islam </h4>
                                <p>CO-Founder</p>
                            </div>
                            <div className='member-social'>
                                <a href="https://www.facebook.com/mazaharul007" target='_blank'>
                                    <FaFacebookSquare />
                                </a>
                                <a href="https://www.linkedin.com/in/mdmazaharulislam/" target='_blank'>
                                    <FaLinkedin />
                                </a>
                            </div>
                        </div> */}

                        <div className='member'>
                            <div className='member-img'>
                                <img src={backendDeveloper} alt="" />
                            </div>
                            <div className='member-intro'>
                                <h4>Md. Tarikul Islam Parvez </h4>
                                <p>FullStack Developer</p>
                            </div>
                            <div className='member-social'>
                                <a href="https://www.facebook.com/parvez80bd" target='_blank'>
                                    <FaFacebookSquare />
                                </a>
                                <a href="https://www.linkedin.com/in/parvez8bd/" target='_blank'>
                                    <FaLinkedin />
                                </a>
                            </div>
                        </div>

                        <div className='member'>
                            <div className='member-img'>
                                <img src={mern_developer} alt="" />
                            </div>
                            <div className='member-intro'>
                                <h4>MD. Kausarul Islam</h4>
                                <p>MERN Developer</p>
                            </div>
                            <div className='member-social'>
                                <a href="https://www.facebook.com/profile.php?id=100013859986135" target='_blank'>
                                    <FaFacebookSquare />
                                </a>
                                <a href="https://www.linkedin.com/in/md-kauserul-islam/" target='_blank'>
                                    <FaLinkedin />
                                </a>
                            </div>
                        </div>

                        <div className='member'>
                            <div className='member-img'>
                                <img src={backendSabron} alt="" />
                            </div>
                            <div className='member-intro'>
                                <h4>Shakuat Shraban </h4>
                                <p>BackEnd Developer</p>
                            </div>
                            <div className='member-social'>
                                <a href="https://www.facebook.com/shakuat.shraban" target='_blank'>
                                    <FaFacebookSquare />
                                </a>
                                <a href="https://www.linkedin.com/in/shakuat-shraban/" target='_blank'>
                                    <FaLinkedin />
                                </a>
                            </div>
                        </div>

                        <div className='member'>
                            <div className='member-img'>
                                <img src={feroj} alt="" />
                            </div>
                            <div className='member-intro'>
                                <h4>Feroj Ahmed</h4>
                                <p>BackEnd Developer</p>
                            </div>
                            <div className='member-social'>
                                <a href="https://www.facebook.com/feroj8bd" target='_blank'>
                                    <FaFacebookSquare />
                                </a>
                                <a href="https://www.linkedin.com/in/feroj8bd/" target='_blank'>
                                    <FaLinkedin />
                                </a>
                            </div>
                        </div>

                        <div className='member'>
                            <div style={{marginTop: "50%"}}>
                                <button style={{background: "#ddd", border: "none", outline: "none", color: "black", padding: "20px 40px", borderRadius: "4px", fontSize: "20px", cursor: "pointer  "}}>Meet More...</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AboutUs;