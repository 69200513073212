import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const Slider = () => {
    const slides = [
        {
            image: "https://www.capitalnumbers.com/images/webp/case-studies-slider/cs-slider-124.webp",
            title: "Django CRM Upgrades & Maintenance for a Leading Auto Parts Distributor",
            tech: 'Tecth Stack:  Django, JavaScript, Vue.js, MySQL',
            description: "We upgraded a Django CRM dashboard for an auto-parts distributor. Because of our solutions, the client can manage his retail stores and activities better.",
            readMore: "Read More",
        },
        {
            image: "https://www.capitalnumbers.com/images/webp/case-studies-slider/cs-slider-122.webp",
            title: "End-to-end Data Engineering Work for a Leading Nonprofit",
            tech: 'Tecth Stack: Java, Spring Boot, MongoDB, Oracle, Angular',
            description: "Capital Numbers did the end-to-end data engineering work for a product review platform. We performed data extraction, transformation, and loading to visualization and helped the client save his costs and bandwidth.",
            readMore: "Read More",
        },
        {
            image: "https://www.capitalnumbers.com/images/webp/case-studies-slider/cs-slider-120.webp",
            title: "UI/UX Development of SaaS App for Data Science",
            tech: 'Tecth Stack:  React JS, Redux Toolkit, TypeScript, Storybook',
            description: "We created a SaaS-based data science app that companies can use to store huge data lists like mails, bills, invoices, CRMs, etc., and use it for future analytics.",
            readMore: "Read More",
        },
    ];

    const interval = 5000;
    const [currentSlide, setCurrentSlide] = useState(0);

    // const handleNext = () => {
    //     setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    // };

    // const handlePrev = () => {
    //     setCurrentSlide((prevSlide) =>
    //         prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    //     );
    // };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, interval);

        return () => {
            clearInterval(intervalId);
        };
    }, [slides.length, interval]);

    return (
        <div className="slider">
            <div className="section-width">
                <h2 className="title slider-title">Some Of Our Works</h2>
            </div>
            <div className="slider-info">
                <div className="slide-project">
                    <div className="slide-content">
                        <h2 className="slider-title">{slides[currentSlide].title}</h2>
                        <p className="slider-tech">{slides[currentSlide].tech}</p>
                        <p className="slider-des">{slides[currentSlide].description}</p>
                        {slides[currentSlide].readMore && (
                            <Link to='/readmore'>
                                <button className="slider-button">
                                    {slides[currentSlide].readMore}
                                    <FaArrowRight className="slider-right-arrow"></FaArrowRight>
                                </button>
                            </Link>
                        )}
                    </div>
                    <img src={slides[currentSlide].image} alt={`Slide ${currentSlide + 1}`} />
                </div>

                {/* <div className="slider-buttons">
                    <button onClick={handlePrev}>Previous</button>
                    <button onClick={handleNext}>Next</button>
                </div> */}

                <div className="slider-dots">
                    {slides.map((slide, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentSlide ? "active" : ""}`}
                            onClick={() => setCurrentSlide(index)}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Slider;
