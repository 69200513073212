import React from 'react'
import caseStudyImage from '../../assets/img/case-study-120.webp'
import icon1 from '../../assets/svg/icon-react.svg'
import icon2 from '../../assets/svg/icon-typescript.svg'
import icon3 from '../../assets/svg/icon-story-book.svg'
import icon4 from '../../assets/svg/icon-redux-saga.svg'
import ch from '../../assets/svg/ch-img.svg'

import client1 from '../../assets/img/the-client.webp'
import Reviews from '../Sections/Reviews'
import ContactUs from '../ContactUs/ContactUs'
import { Helmet } from 'react-helmet'

const ReadMore = () => {
    return (
        <div className='read-more'>

            <Helmet>
                <title>Read More -Web Arts Factory </title>
            </Helmet>

            <div className='case-study read-more-section'>
                <div className='flex container'>
                    <div>
                        <img className='case-study-img' src={caseStudyImage} alt="" />
                    </div>
                    <div className='case-study-info'>
                        <h3>CASE STUDY</h3>
                        <h2>UI/UX Development of SaaS App for Data Science</h2>
                    </div>
                </div>
            </div>

            <div className='technology-stack'>
                <div className="container">
                    <p>Technology Stack</p>
                    <div className='technology-info'>
                        <span>
                            <img className='technology-icon' src={icon1} alt="" />
                            <p>ReactJs</p>
                        </span>
                        <span>
                            <img className='technology-icon' src={icon2} alt="" />
                            <p>Redux Toolkit</p>
                        </span>
                        <span>
                            <img className='technology-icon' src={icon3} alt="" />
                            <p>TypeScript</p>
                        </span>
                        <span>
                            <img className='technology-icon' src={icon4} alt="" />
                            <p>Storybook</p>
                        </span>
                    </div>
                    <hr />
                </div>
            </div>

            <div className='the-client'>
                <div className="container">
                    <div className="the-client-main-body">
                        <div className='client-img'>
                            <img src={client1} alt="" />
                        </div>
                        <div className='client-info'>
                            <h4>The Client</h4>
                            <p>This data science app is a configurable SaaS-based platform that companies can buy and install to store and visualize data. The application allows companies to create huge custom databases for mailing lists, bills, invoices, CRMs, HR management, managing and sharing documents, etc., and use them for analytics in the future. Companies of any size can install this software to view data in beautiful graphical formats. Those tired of looking at boring data sheets can use this software to visualize raw data in color-coded graphs.</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className='the-challenge container'>

                <div className='the-challenge-left-site'>
                    <div className='flex'>
                        <div className='challenge-icon'>
                            <img src={ch} alt="" />
                        </div>
                        <div>
                            <h3 className='the-challenge-title'>The Challenge</h3>
                        </div>
                    </div>
                    <div className='the-challenge-info'>
                        <p>The client who contacted us for this project already had the backend environment set up. But, the app’s frontend dashboard wasn’t ready. As a result, raw data would remain stored as raw information in the backend. It was impossible to discern anything from them. This was when the client sought Capital Numbers’ help to create a front-end dashboard that would transform random backend data into insightful charts.</p>
                    </div>
                </div>

                <div className='the-challenge-info'>
                    <p>We had comprehensive knowledge of Data Structures and Algorithms (DSA). So, we knew the challenges and programmatic ways of translating backend data into front-end charts. We had thorough concepts of basic data structures like Arrays and Linked lists and advanced data structures like Disjoint Sets. So, the client trusted us with this project that needed handling massive and dynamic data sets for visualizations.</p>

                    <p>The client also asked us to add several cosmetic changes to the frontend dashboard. For example, we needed to custom-integrate new sliders, buttons, icons, grids, overlays, side scrolls, dropdowns, panels, clocks, event-based calendars, and other components.</p>

                    <p>Moreover, we needed to convert the existing JavaScript code into TypeScript to make the codebase error-free, reusable, and more secure. We needed to handle all of these and suggest improvements when required. We also had to do the app's unit testing to ensure the data stores reflected the visual story well.</p>
                </div>

            </div>

            <div>
                <Reviews></Reviews>
            </div>

            <div>
                <ContactUs></ContactUs>
            </div>
        </div>
    )
}

export default ReadMore