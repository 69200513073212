import React from 'react'
import blueMark from '../../assets/img/blue-chek-icon-XY40.png'

const ResonToChoose = () => {
    return (
        <div className='reason-to-choose-section'>
            <div className="container">
                <div className='section-width'>
                    <h2 className='title'>Reason to Choose The Web Arts Factory as your Trusted Digital Transformation</h2>
                    <p className='subTitle'>Are you ready to take your small business’ online presence to the next level? Invest in our SEO services now and we will show you how! Here are some more reasons for you to choose us as your trusted advisors in your SEO growth strategy:</p>
                </div>

                <div className='reson-description'>
                    <div className='reson-info'>
                        <div>
                            <img src={blueMark} alt="" />
                        </div>
                        <div className='reson-subTitle'>
                            <span><strong>Industry Experience & Expertise:</strong>  A firm with our professional competence, abilities and track record can be found nowhere else! Simply put, our team is uniquely qualified to help you.</span>

                        </div>
                    </div>
                    <div className='reson-info'>
                        <div>
                            <img src={blueMark} alt="" />
                        </div>
                        <div className='reson-subTitle'>
                            <span><strong>Credibility & Skills:</strong> You are in safe hands with us! Our experience has cultivated a reliable approach to digital marketing. As a result, we are now trusted by business owners around the world.</span>

                        </div>
                    </div>
                    <div className='reson-info'>
                        <div>
                            <img src={blueMark} alt="" />
                        </div>
                        <div className='reson-subTitle'>
                            <span><strong>White Hat Practices:</strong>  We are a White Hat SEO service provider and only recommend researched practices that provide value to human audiences, and not the search algorithm alone.</span>

                        </div>
                    </div>
                    <div className='reson-info'>
                        <div>
                            <img src={blueMark} alt="" />
                        </div>
                        <div className='reson-subTitle'>
                            <span><strong>Cost-Effective Growth:</strong>  Our digital marketing strategies are always focused on efficiently growing your business for the least possible cost. Rest assured that your timely investment into our SEO services will net you a profit.</span>

                        </div>
                    </div>
                    <div className='reson-info'>
                        <div>
                            <img src={blueMark} alt="" />
                        </div>
                        <div className='reson-subTitle'>
                            <span><strong>Dedicated Project Manager:</strong>   For each of our client projects, we assign a dedicated project manager. They will focus on your project alone, strategizing everything from its roadmap to its execution to its deliverable. </span>

                        </div>
                    </div>
                    <div className='reson-info'>
                        <div>
                            <img src={blueMark} alt="" />
                        </div>
                        <div className='reson-subTitle'>
                            <span><strong>Client Portal:</strong>  We give each of our clients access to an individual client portal. From your portal view, you will be able to see everything related to your project, including our work roadmap and payment history.</span>

                        </div>
                    </div>
                    <div className='reson-info'>
                        <div>
                            <img src={blueMark} alt="" />
                        </div>
                        <div className='reson-subTitle'>
                            <span><strong>Hands-On Communication:</strong>  Chat with us whenever you need to! As we complete our work, we aim to provide great customer service at every stage. We employ a hands-on communication process throughout our team and with all of our clients.</span>

                        </div>
                    </div>
                    <div className='reson-info'>
                        <div>
                            <img src={blueMark} alt="" />
                        </div>
                        <div className='reson-subTitle'>
                            <span><strong>Reporting and Documentation:</strong>  Documenting the work steps throughout our process allows you to confirm our promised services from the original roadmap. Our team takes care to keep a record of all changes to your deliverable.</span>

                        </div>
                    </div>
                    <div className='reson-info'>
                        <div>
                            <img src={blueMark} alt="" />
                        </div>
                        <div className='reson-subTitle'>
                            <span><strong>On time Delivery:</strong>  From the start of every project, we always try to stick to the deadline(s) outlined in the original roadmap. When we encounter delays or challenges, we promise to always communicate them and work through them with you. </span>

                        </div>
                    </div>
                    <div className='reson-info'>
                        <div>
                            <img src={blueMark} alt="" />
                        </div>
                        <div className='reson-subTitle'>
                            <span><strong>Post-Project Support:</strong>  After the project is completed and we have delivered on our service offering to you, the work is not yet done! We promise to provide post-project support as you begin operating your business with the implemented SEO changes.</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResonToChoose