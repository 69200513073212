import React from 'react'

const WhyChooseUS = () => {
    return (
        <div className='chooseUs-section'>
            <div className="container">
                <div className='flex'>
                    <div className='why-choose-web-arts-fact'>
                        <h4>Why Choose <span className='web-arts-fact'>Webartsfactory, <small style={{fontSize: "20px"}}>a sister concern of Morsheda PUC Ltd</small></span></h4>
                    </div>
                    <div className='why-choose-info'>
                        <p>We're a Software Company that Makes Things Easy. Choose Us for Simple Solutions and Smooth Experiences!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseUS