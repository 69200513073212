import React from 'react'
import img1 from '../../assets/svg/Services/service-icon-2.svg'
import img2 from '../../assets/svg/Services/service-icon-1.svg'
import img3 from '../../assets/svg/Services/cs-icon5.png'
import img4 from '../../assets/svg/Services/service-icon-2.05aa97d5.svg'

const Services = () => {
    
    return (
        <div className='service-section'>
            <div className='container'>
                <div className='section-width'>
                    <h1 className="title">Our Awesome Services</h1>
                    <p className="subTitle">
                    Webartsfactory, a sister concern of Morsheda PUC Ltd is a leading web solution, software management & digital marketing service provider and one of the fastest growing companies in city of Mymensingh, Bangladesh.
                    </p>
                </div>
                <div className='service'>
                    <div className='service-info'>
                        <div className='service-icon'>
                            <img src={img1}></img>
                        </div>
                        <div>
                            <h2>Web Development</h2>
                            <p>We use state-of-the-art technologies and development practices to create custom web applications for your business. Our web design team conceptualizes and creates each application from scratch, ensuring it meets your unique business.</p>
                        </div>
                    </div>
                    <div className='service-info'>
                        <div className='service-icon'>
                            <img src={img2}></img>
                        </div>
                        <div>
                            <h2>Software Development</h2>
                            <p>Get the custom functionality and features required to meet your business or industry's unique needs. Our custom software solutions help you address your present needs and, more importantly, your needs for the future.</p>
                        </div>
                    </div>
                    <div className='service-info'>
                        <div className='service-icon'>
                            <img src={img3}></img>
                        </div>
                        <div>
                            <h2>Backend Development</h2>
                            <p>Tap into our on-demand DevOps expertise to achieve the cloud-enabled business transformation that’s aimed at cost-saving, agility, speed-to-delivery, and innovation.</p>
                        </div>
                    </div>
                    <div className='service-info'>
                        <div className='service-icon'>
                            <img src={img4}></img>
                        </div>
                        <div>
                            <h2>Mobile App Development</h2>
                            <p>Our developers build native or hybrid apps for iOS and Android using the latest tech stack. We have expertise in fintech, edtech, healthcare, streaming industries, and more.</p>
                        </div>
                    </div>
                    <div className='service-info'>
                        <div className='service-icon'>
                            <img src={img4}></img>
                        </div>
                        <div>
                            <h2>E-Commerce Website Development</h2>
                            <p>Our digital transformation solutions help enterprises develop the right DX framework upon accessing their digital transformation readiness to induce digital capabilities using analytics, automation, and AI, modernizing their business models and processes for improved customer experiences.</p>
                        </div>
                    </div>
                    <div className='service-info'>
                        <div className='service-icon'>
                            <img src={img4}></img>
                        </div>
                        <div>
                            <h2>UI/UX Development</h2>
                            <p>We offer end-to-end Blockchain development solutions to help clients grow in areas like Cryptocurrency, NFT Marketplace, Smart Contract, ICO, DeFi, dApp, etc.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services