import React from 'react'
import image from '../../assets/img/hire expert.jpg'

const HireExperts = () => {

    return (
        <div className='hire-expert-section'>
            <div className="container">
                <div className='section-width'>
                    <h2 className='title'>Hire Experts in Today's Leading Technologies</h2>
                    <p className='subTitle'>Empower Your Digital Transformation with Expertise in
                        <span className='d-block'> Cutting-Edge Tools and Frameworks</span></p>
                </div>
                <div className='flex'>
                    <div>
                        <img className='hire-expert-img' src={image} alt="" />
                    </div>
                    <div className='hire-expert-info'>
                        <p>As a leading Software Solutions company, we have an in-house team of 750+ experts specializing in over 40 technologies. Our proficiency includes a wide range of programming languages, web and mobile frameworks, databases, and cloud platforms. We are also adept in containerization, orchestration, continuous integration and deployment (CI/CD), big data, and AI/ML and Blockchain ensuring we are equipped to handle all your software needs.</p>
                        <div className='btn-book-all'>
                            <button className='btn-hire-experts'>Book All</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HireExperts