import React from 'react'

import img14 from '../../assets/img/clients/c1.png'
import img15 from '../../assets/img/clients/c2.png'
import img16 from '../../assets/img/clients/c7.png'
import img17 from '../../assets/img/clients/c4.png'
import img18 from '../../assets/img/clients/c5.png'
import img19 from '../../assets/img/clients/c6.png'

const Reviews = () => {
    return (
        <div>
            <div className="main-section py-5 section-bg">
                <div className='container'>
                    <div className='text-center' style={{ marginBottom: "30px" }}>
                        <h2 className='text-center' style={{ fontSize: "30px", textAlign: "center" }}>Great Reviews</h2>
                        <p style={{ textAlign: "center", fontSize: "20px" }}>97 Out Of 100 Clients Have Given Us A Five Star Rating On Google & Clutch</p>
                    </div>

                    <div className="review">
                        <div className=" review-slider">
                            <div className="text-center border shadow rounded single-review" style={{ padding: "25px", boxShadow: "4px 4px 4px 4px #ddd", borderRadius: "10px" }}>
                                <div className="testimonial-text text-center p-4" style={{ marginBottom: "20px" }}>
                                    <p className="mb-5  review-text" style={{fontSize: "20px"}}>
                                        "Capital Numbers was easy to work with, and they were always available. They were quick and efficient and their work was very good."
                                    </p>
                                </div>
                                <hr />
                                <div className='flex pt-3 border-t  gap-3 justify-center items-center' style={{ marginTop: "20px", display: 'flex', alignItems: "center", gap: "20px" }}>
                                    <img
                                        className="border rounded-full p-2 mb-3"
                                        src={img14}
                                        style={{ width: 80, height: 80, borderRadius: "100%", border: "1px solid #ddd", padding: "8px" }}
                                    />
                                    <div className='text-start'>
                                        <h5 className="mb-0 review-text" style={{ fontSize: "18px" }}>P. Attur</h5>
                                        <p className='review-text'>CIO, Hudson Regional Hospital</p>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center border rounded single-review" style={{ padding: "25px", boxShadow: "4px 4px 4px 4px #ddd", borderRadius: "10px" }}>
                                <div className="testimonial-text text-center p-4" style={{ marginBottom: "20px" }}>
                                    <p className="mb-5  review-text" style={{fontSize: "20px"}}>
                                        "They are a well-structured team and that impressed us the most. Capital Numbers provides a high level of customer service and support."
                                    </p>
                                </div>
                                <hr />
                                <div className='flex pt-3 border-t  gap-3 justify-center items-center' style={{ marginTop: "20px", display: 'flex', alignItems: "center", gap: "20px" }}>
                                    <img
                                        className="border rounded-full p-2 mb-3"
                                        src={img15}
                                        style={{ width: 80, height: 80, borderRadius: "100%", border: "1px solid #ddd", padding: "8px" }}
                                    />
                                    <div className='text-start'>
                                        <h5 className="mb-0 review-text" style={{ fontSize: "18px" }}>Will Hershfeld</h5>
                                        <p className='review-text'>Director of Web Services, AdsIntelligence</p>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center border shadow rounded single-review" style={{ padding: "25px", boxShadow: "4px 4px 4px 4px #ddd", borderRadius: "10px" }}>
                                <div className="testimonial-text text-center p-4" style={{ marginBottom: "20px" }}>
                                    <p className="mb-5  review-text" style={{fontSize: "20px"}}>
                                        "Their willingness to be flexible impressed us the most. Capital Numbers has been a trusted resource & partner for years."
                                    </p>
                                </div>
                                <hr />
                                <div className='flex pt-3 border-t  gap-3 justify-center items-center' style={{ marginTop: "20px", display: 'flex', alignItems: "center", gap: "20px" }}>
                                    <img
                                        className="border rounded-full p-2 mb-3"
                                        src={img16}
                                        style={{ width: 80, height: 80, borderRadius: "100%", border: "1px solid #ddd", padding: "8px" }}
                                    />
                                    <div className='text-start'>
                                        <h5 className="mb-0 review-text" style={{ fontSize: "18px" }}>Twakiente Kubor</h5>
                                        <p className='review-text'>Owner, Examtice.com</p>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center border shadow rounded single-review" style={{ padding: "25px", boxShadow: "4px 4px 4px 4px #ddd", borderRadius: "10px" }}>
                                <div className="testimonial-text text-center p-4" style={{ marginBottom: "20px" }}>
                                    <p className="mb-5  review-text" style={{fontSize: "20px"}}>
                                        "Capital Numbers provides a high level of customer service and support. Their willingness to be flexible impressed us the most."
                                    </p>
                                </div>
                                <hr />
                                <div className='flex pt-3 border-t  gap-3 justify-center items-center' style={{ marginTop: "20px", display: 'flex', alignItems: "center", gap: "20px" }}>
                                    <img
                                        className="border rounded-full p-2 mb-3"
                                        src={img17}
                                        style={{ width: 80, height: 80, borderRadius: "100%", border: "1px solid #ddd", padding: "8px" }}
                                    />
                                    <div className='text-start'>
                                        <h5 className="mb-0 review-text" style={{ fontSize: "18px" }}>Katherine Mao</h5>
                                        <p className='review-text'>Co-Founder, Yeeo Inc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="review-slider">
                            <div className="text-center border shadow rounded single-review" style={{ padding: "25px", boxShadow: "4px 4px 4px 4px #ddd", borderRadius: "10px" }}>
                                <div className="testimonial-text text-center p-4" style={{ marginBottom: "20px" }}>
                                    <p className="mb-5  review-text" style={{fontSize: "20px"}}>
                                        "They were quick and efficient and their work was very good.They are a well-structured team and that impressed us the most."
                                    </p>
                                </div>
                                <hr />
                                <div className='flex pt-3 border-t  gap-3 justify-center items-center' style={{ marginTop: "20px", display: 'flex', alignItems: "center", gap: "20px" }}>
                                    <img
                                        className="border rounded-full p-2 mb-3"
                                        src={img18}
                                        style={{ width: 80, height: 80, borderRadius: "100%", border: "1px solid #ddd", padding: "8px" }}
                                    />
                                    <div className='text-start'>
                                        <h5 className="mb-0 review-text" style={{ fontSize: "18px" }}>Bob Norberg</h5>
                                        <p className='review-text'>CMO, Cloud Age Solutions</p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center border shadow rounded single-review" style={{ padding: "25px", boxShadow: "4px 4px 4px 4px #ddd", borderRadius: "10px" }}>
                                <div className="testimonial-text text-center p-4" style={{ marginBottom: "20px" }}>
                                    <p className="mb-5  review-text" style={{fontSize: "20px"}}>
                                        "Capital Numbers has been a trusted resource & partner for years. Capital Numbers was easy to work with, and they were always available"
                                    </p>
                                </div>
                                <hr />
                                <div className='flex pt-3 border-t  gap-3 justify-center items-center' style={{ marginTop: "20px", display: 'flex', alignItems: "center", gap: "20px" }}>
                                    <img
                                        className="border rounded-full p-2 mb-3"
                                        src={img19}
                                        style={{ width: 80, height: 80, borderRadius: "100%", border: "1px solid #ddd", padding: "8px" }}
                                    />
                                    <div className='text-start'>
                                        <h5 className="mb-0 review-text" style={{ fontSize: "18px" }}>Scott R. Wells</h5>
                                        <p className='review-text'>Visionary, ConversionFormula</p>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center border shadow rounded single-review" style={{ padding: "25px", boxShadow: "4px 4px 4px 4px #ddd", borderRadius: "10px" }}>
                                <div className="testimonial-text text-center p-4" style={{ marginBottom: "20px" }}>
                                    <p className="mb-5  review-text" style={{fontSize: "20px"}}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam quam nihil nemo animi? Odit corrupti accusamus itaque eligendi optio sunt?
                                    </p>
                                </div>
                                <hr />
                                <div className='flex pt-3 border-t  gap-3 justify-center items-center' style={{ marginTop: "20px", display: 'flex', alignItems: "center", gap: "20px" }}>
                                    <img
                                        className="border rounded-full p-2 mb-3"
                                        src={img15}
                                        style={{ width: 80, height: 80, borderRadius: "100%", border: "1px solid #ddd", padding: "8px" }}
                                    />
                                    <div className='text-start'>
                                        <h5 className="mb-0 review-text" style={{ fontSize: "18px" }}>Nirjor Rahman</h5>
                                        <p className='review-text'>Web Development with Programming</p>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center border shadow rounded single-review" style={{ padding: "25px", boxShadow: "4px 4px 4px 4px #ddd", borderRadius: "10px" }}>
                                <div className="testimonial-text text-center p-4" style={{ marginBottom: "20px" }}>
                                    <p className="mb-5  review-text" style={{fontSize: "20px"}}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam quam nihil nemo animi? Odit corrupti accusamus itaque eligendi optio sunt?
                                    </p>
                                </div>
                                <hr />
                                <div className='flex pt-3 border-t  gap-3 justify-center items-center' style={{ marginTop: "20px", display: 'flex', alignItems: "center", gap: "20px" }}>
                                    <img
                                        className="border rounded-full p-2 mb-3"
                                        src={img15}
                                        style={{ width: 80, height: 80, borderRadius: "100%", border: "1px solid #ddd", padding: "8px" }}
                                    />
                                    <div className='text-start'>
                                        <h5 className="mb-0 review-text" style={{ fontSize: "18px" }}>Nirjor Rahman</h5>
                                        <p className='review-text'>Web Development with Programming</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Reviews
