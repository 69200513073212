import React from 'react'

import img1 from '../../assets/svg/devzet.svg'
import img2 from '../../assets/img/extreme_logo.jpeg'
import img3 from '../../assets/img/examtice_logo_dark.jpg'
import img4 from '../../assets/svg/savers.png'
import img5 from '../../assets/svg/eur.png'
import img6 from '../../assets/svg/mhec.png'
import img7 from '../../assets/svg/designWaver.svg'
import img8 from '../../assets/svg/Logo-white-name-E-1.png'
import img9 from '../../assets/svg/sma-shop.png'
import img10 from '../../assets/svg/bluegrass.svg'
import img11 from '../../assets/svg/p11.svg'
import img12 from '../../assets/svg/p12.svg'

const BrandsWeWork = () => {
    return (
        <div className='brands-we-work-section'>
            <div className="container">
                <div className='section-width'>
                    <h2 className='title'>Local & International Brands We Work With</h2>
                    <p className='subTitle'>We have extensive experience in delivering top-notch tailored SEO solutions across a wide range of industries. Uncover all the brands we have worked with so far from here and let the results speak on our behalf.</p>
                </div>

                <div className='brand'>
                    <img style={{ width: "150px", margin: "auto" }} src={img1} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img2} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img3} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img4} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img5} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img6} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img7} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img8} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img9} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img10} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img11} alt="" />
                    <img style={{ width: "150px", margin: "auto" }} src={img12} alt="" />
                </div>
            </div>
        </div>
    )
}

export default BrandsWeWork