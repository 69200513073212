import React from 'react'
import Header from '../Sections/Header'
import Services from '../Sections/Services'
import Projects from '../Sections/Projects'
import Blog from '../Sections/Blog'
import Pricing from '../Sections/Pricing'
import Contact from '../Sections/Contact'
import TopNavbar from '../Nav/TopNavbar'
import GrowYourBusness from '../Sections/GrowYourBusness'
import Reviews from '../Sections/Reviews'
import ContactUs from '../ContactUs/ContactUs'
import CustomSoftwareSolutions from '../Sections/CustomSoftwareSolutions'
import Slider from '../Sections/Slider'
import FAQ from '../FAQ/FAQ'
import SliderProject from '../SliderProject/SliderProject'
import HireExperts from '../HireExperts/HireExperts'
import WhyChooseUS from '../WhyChooseUS/WhyChooseUS'
import ResonToChoose from '../ReasonToChoose/ResonToChoose'
import BrandsWeWork from '../BrandsWeWork/BrandsWeWork'
import { Helmet } from 'react-helmet'
const Home = () => {
    return (
        <div>
            <Helmet>
                <title>Web Arts Factory || Your Vision, Our Code</title>
            </Helmet>

            <TopNavbar></TopNavbar>
            <Header></Header>
            <CustomSoftwareSolutions></CustomSoftwareSolutions>
            <Services></Services>
            {/* <Slider></Slider> */}
            <HireExperts></HireExperts>
            <WhyChooseUS></WhyChooseUS>
            <Projects></Projects>
            <GrowYourBusness></GrowYourBusness>
            <ResonToChoose></ResonToChoose>
            <Reviews></Reviews>
            <BrandsWeWork></BrandsWeWork>
            {/* <SliderProject></SliderProject> */}
            <FAQ></FAQ>
            {/* <Blog></Blog> */}
            {/* <Pricing></Pricing> */}
            {/* <Contact></Contact> */}
            {/* <ContactUs></ContactUs> */}
        </div>
    )
}

export default Home