import React from 'react'
import picture from '../../assets/img/blog-page.jpg'
import blog1 from '../../assets/img/b1.jpg'
import blog2 from '../../assets/img/b2.jpg'
import blog3 from '../../assets/img/b3.jpg'
import blog4 from '../../assets/img/b4.jpg'
import sideBannar from '../../assets/img/banner-wid.jpg'

const BlogPageTwo = () => {
    return (
        <div className='blog-page'>
            <div className="container">
                <div>
                    <div className='blog-img-div'>
                        <img className='blog-img' src={picture} alt="" />
                    </div>
                    <div>
                        <h4 className='blog-title'>GREEN CORNER IN MY HOME</h4>
                        <p className='blog-pera'>Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque euro, pretium quis, sem. Nulla onsequat massa quis enim. Donec pede justo fringilla vel aliquet nec vulputate eget. Lorem ispum dolore siamet ipsum dolor.</p>
                        <p className='blog-pera'>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumquer nihil impedit quo minus id quod maxime placeat facere. At vero eos et accusamus et iusto odio dignissimos ducimus quilor.</p>
                    </div>
                </div>
                <hr className='hr-tag' />

                <div className='full-blog'>

                    <div>
                        <div className='single-blog'>
                            <div>
                                <img className='single-blog-img' src={blog1} alt="" />
                            </div>
                            <div>
                                <span className='moment'>Moments</span>
                                <p className='single-blog-title'>TWINKLE TWINKLE LITTLE STAR</p>
                                <span className='date'>June 7, 2017</span>
                                <p className='single-blog-info'>Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque euro, pretium quis, sem. Nulla…</p>
                                <div>
                                    <button className='read-more-btn'>Read More</button>
                                </div>
                            </div>
                        </div>
                        <div className='single-blog'>
                            <div>
                                <img className='single-blog-img' src={blog2} alt="" />
                            </div>
                            <div>
                                <span className='moment'>Moments</span>
                                <p className='single-blog-title'>TWINKLE TWINKLE LITTLE STAR</p>
                                <span className='date'>June 7, 2017</span>
                                <p className='single-blog-info'>Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque euro, pretium quis, sem. Nulla…</p>
                                <div>
                                    <button className='read-more-btn'>Read More</button>
                                </div>
                            </div>
                        </div>
                        <div className='single-blog'>
                            <div>
                                <img className='single-blog-img' src={blog3} alt="" />
                            </div>
                            <div>
                                <span className='moment'>Moments</span>
                                <p className='single-blog-title'>TWINKLE TWINKLE LITTLE STAR</p>
                                <span className='date'>June 7, 2017</span>
                                <p className='single-blog-info'>Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque euro, pretium quis, sem. Nulla…</p>
                                <div>
                                    <button className='read-more-btn'>Read More</button>
                                </div>
                            </div>
                        </div>
                        <div className='single-blog'>
                            <div>
                                <img className='single-blog-img' src={blog4} alt="" />
                            </div>
                            <div>
                                <span className='moment'>Moments</span>
                                <p className='single-blog-title'>TWINKLE TWINKLE LITTLE STAR</p>
                                <span className='date'>June 7, 2017</span>
                                <p className='single-blog-info'>Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque euro, pretium quis, sem. Nulla…</p>
                                <div>
                                    <button className='read-more-btn'>Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='side-bannar'>
                        <div>
                            <img className='single-blog-img' src={sideBannar} alt="" />
                        </div>
                        <div className='newsletter'>
                            <span className='newsletter-pera'>NEWSLETTER</span>
                        </div>
                        <div className='subscribe'>
                            <span className='subscribe-title'>Subscribe my Newsletter for new blog posts. Stay updated from your inbox!</span>
                            <div className='input-div'>
                                <input className='input-field' type="text" placeholder='Name...' />
                            </div>
                            <div>
                                <input className='input-field' type="text" placeholder='Email...' />
                            </div>
                            <div className='newsletter'>
                                <span className='newsletter-pera'>SUBSCRIPT NOW</span>
                            </div>
                        </div>

                        <div>
                            <div className='newsletter'>
                                <span className='newsletter-pera'>Recent Post</span>
                            </div>
                            <div className='side-bannar-blog'>
                                <div>
                                    <img className='side-bannar-blog-img' src={blog1} alt="" />
                                </div>
                                <div>
                                    <p className='side-bannar-blog-title'>Green Corner in My Home</p>
                                    <span className='date'>June 7, 2017</span>
                                </div>
                            </div>
                            <div className='side-bannar-blog'>
                                <div>
                                    <img className='side-bannar-blog-img' src={blog2} alt="" />
                                </div>
                                <div>
                                    <p className='side-bannar-blog-title'>Green Corner in My Home</p>
                                    <span className='date'>June 7, 2017</span>
                                </div>
                            </div>
                            <div className='side-bannar-blog'>
                                <div>
                                    <img className='side-bannar-blog-img' src={blog3} alt="" />
                                </div>
                                <div>
                                    <p className='side-bannar-blog-title'>Green Corner in My Home</p>
                                    <span className='date'>June 7, 2017</span>
                                </div>
                            </div>
                            <div className='side-bannar-blog'>
                                <div>
                                    <img className='side-bannar-blog-img' src={blog4} alt="" />
                                </div>
                                <div>
                                    <p className='side-bannar-blog-title'>Green Corner in My Home</p>
                                    <span className='date'>June 7, 2017</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BlogPageTwo