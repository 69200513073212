import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import { RouterProvider } from 'react-router-dom'
import { router } from "./Routes/Routes.js";


export default function App({title}) {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />

        <title>{title}</title>

      </Helmet>


      {/* <Landing /> */}
      <RouterProvider router={router}></RouterProvider>
    </>
  );
}

