import React, { useEffect }  from 'react'
import img5 from '../../assets/img/pm-1.svg'
import img1 from '../../assets/img/new-form-icon-1.svg'
import img2 from '../../assets/img/new-form-icon-2.svg'
import img3 from '../../assets/img/new-form-icon-3.svg'
import img4 from '../../assets/img/new-form-icon-4.svg'
import indiaFlag from '../../assets/img/bd.png'
import unitedKingdom from '../../assets/img/united-kingdom.png'
import usaFlag from '../../assets/img/usa-flag-icon.svg'
import contactLocation from '../../assets/img/contact-location-building.webp'

import award1 from '../../assets/svg/a1.svg'
import award2 from '../../assets/svg/a2.svg'
import award3 from '../../assets/svg/a3.svg'
import award4 from '../../assets/svg/a4.svg'
import award5 from '../../assets/svg/a5.svg'
import award6 from '../../assets/svg/a6.svg'
import award7 from '../../assets/svg/a7.svg'
import award8 from '../../assets/svg/a8.svg'
import award9 from '../../assets/svg/a9.svg'
import award10 from '../../assets/svg/a10.svg'
import { FaLocationArrow, FaMailBulk, FaPhone, FaSearchLocation, FaVoicemail } from 'react-icons/fa'
import { Helmet } from 'react-helmet'


const ContactUsFullPage = () => {

    // const sendMail = () =>{

    // }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div>

            <Helmet>
                <title>Contact Us -Web Arts Factory</title>
            </Helmet>

            <div className='contact contact-us-full-page'>
                <div className='container'>
                    <div className='section-width'>
                        <h2 className='contact-title'>We’d Love To Hear From You</h2>
                        <p className='contact-subTitle'>Get Custom Solutions, Recommendations, Resumes, or, Estimates.
                            Confidentiality & Same Day Response Guaranteed!</p>
                    </div>
                    <div className='contact-mail'>
                        <div className='location-left-site'>
                            <div>
                            <h4>Our Locations</h4>
                            <div>
                                <div className='india-location'>

                                    <img style={{ borderRadius: "100%", width: "40px" }} src={indiaFlag} alt="" />


                                    <h4>Bangladesh</h4>
                                </div>
                                <div className='address-contact'>
                                    <div>
                                        <div>
                                            <strong>Mymenshing</strong>
                                            <p style={{ marginBottom: "10px", marginTop: "10px" }}> <FaLocationArrow /> 56, Shankipara Health Office Lane, 3 S A Sarker Rd, Mymensingh 2200</p>
                                            <p style={{}}><FaMailBulk /> service@webartsfactory.com</p>
                                            <p className='contact-number'><FaPhone /> +8801685796895</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                            {/* <div>
                                <div className='india-location'>

                                    <img style={{ width: "60px" }} src={unitedKingdom} alt="" />

                                    <h4>United Kingdom</h4>

                                </div>
                                <div className='address-contact'>
                                    <div>
                                        <div>
                                            <strong>London</strong>
                                            <p style={{ marginBottom: "10px", marginTop: "10px" }}> <FaLocationArrow /> 21B Petticoat Square, E1 7EF, United Kingdom</p>
                                            
                                            <p className='contact-number'><FaPhone /> +19082065812 </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        <div className='contact-right-site'>
                            <form action="https://www.webartsfactory.com/mail.php" method='POST'>
                                <div>
                                    <input className='input-text' type="text" name='name' placeholder='Name' />
                                </div>
                                <div>
                                    <input className='input-text' type="text" name='email' placeholder='Work Email' />
                                </div>
                                <div>
                                    <input className='input-text' type="text" name="phone" placeholder='Mobile Number' />
                                </div>
                                <div>
                                    <input className='input-text' type="text" name='company' placeholder='Company' />
                                </div>
                                <div>
                                    <input className='input-text' type="text" name='subject' placeholder='Subject' />
                                </div>
                                <div>
                                    <textarea className='input-text' name="message" id="" placeholder='Your Message'></textarea>
                                </div>
                                <div className='contact-btn'>
                                    <button type='submit'>Send Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='location-info'> */}

            <div className="location-image" style={{ marginTop: "50px" }}>
                <div className='container'>

                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d167893.33661564853!2d90.25987498105964!3d24.750364214189595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x37564f5cf5ca2115%3A0x6248047dcdfdff3e!2sWeb%20Arts%20Factory!5e0!3m2!1sen!2sbd!4v1705210790940!5m2!1sen!2sbd" width="100%" height="500" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.0746797915676!2d90.3956596!3d24.758628500000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x37564fbb0bde6299%3A0x3b1f026add1c527f!2sShankipara%20Health%20Office%20lane%2CMymensingh!5e0!3m2!1sen!2sbd!4v1724650899849!5m2!1sen!2sbd"width="100%" height="500" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            {/* </div> */}

            <div className='recent-award'>
                <div className="container">
                    <h3 className='title'>Recent Awards & Certifications</h3>
                    <div className='award'>
                        <img className='award-image' src={award1} alt="" />
                        <img className='award-image' src={award2} alt="" />
                        <img className='award-image' src={award3} alt="" />
                        <img className='award-image' src={award4} alt="" />
                        <img className='award-image' src={award5} alt="" />
                        <img className='award-image' src={award6} alt="" />
                        <img className='award-image' src={award7} alt="" />
                        <img className='award-image' src={award8} alt="" />
                        <img className='award-image' src={award9} alt="" />
                        <img className='award-image' src={award10} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsFullPage