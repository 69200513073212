import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/img/logo.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
    const location = useLocation();
    const pathname = location.pathname;
    const [y, setY] = useState(window.scrollY);
    const [sidebarOpen, toggleSidebar] = useState(false);
    const [activeMenu, setActiveMenu] = useState("home");

    console.log(pathname)

    useEffect(() => {
        window.addEventListener("scroll", () => setY(window.scrollY));
        return () => {
            window.removeEventListener("scroll", () => setY(window.scrollY));
        };

    }, [y]);

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
    };




    return (
        <>
            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
            <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
                <NavInner className="container flexSpaceCenter">
                    <Link to="/" style={{color : 'black'}}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "end" }}>
                            <img style={{ width: "80px", textAlign: "end" }} src={LogoIcon} alt="" />
                            <h1 style={{ marginTop: "15px", marginLeft: "-10px" }} className="font20 extraBold agency-full-name">
                                Web Arts Factory
                            </h1>
                        </div>
                    </Link>
                    {/* </Link> */}
                    <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
                        <BurgerIcon />
                    </BurderWrapper>
                    <UlWrapper className="flexNullCenter">
                        <Link className={`${pathname === '/' ? "active" : "nav-link"}`} style={{ padding: "10px 15px" }} to="/" spy={true} smooth={true} offset={-80}>
                            Home
                        </Link>
                        <li>
                            <Link className={`${pathname === '/services' ? "active" : "nav-link"}`} style={{ padding: "10px 15px" }} to="/services" spy={true} smooth={true} offset={-80}>
                                Services
                            </Link>
                        </li>
                        <li>
                            <Link className={`${pathname === '/projects' ? "active" : "nav-link"}`} style={{ padding: "10px 15px" }} to="/projects" spy={true} smooth={true} offset={-80}>
                                Projects
                            </Link>
                        </li>
                        <li>
                            <Link className={`${pathname === '/about-us' ? "active" : "nav-link"}`} style={{ padding: "10px 15px" }} to="/about-us" spy={true} smooth={true} offset={-80}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link className={`${pathname === '/contact-us' ? "active" : "nav-link"}`} style={{ padding: "10px 15px" }} to="/contact-us" spy={true} smooth={true} offset={-80}>
                                Contact
                            </Link>
                        </li>

                    </UlWrapper>
                </NavInner>
            </Wrapper>
        </>
    );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


