import React, { useState } from "react";
import styled from "styled-components";
import '../../style/Project.css'
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/Examtice.jpg";
import ProjectImg2 from "../../assets/img/Extremeranks.jpg";
import ProjectImg3 from "../../assets/img/Savershall.jpg";
import ProjectImg4 from "../../assets/img/Eurasiasupplies.jpg";
import ProjectImg5 from "../../assets/img/mheec.jpg";
import ProjectImg6 from "../../assets/img/Designwaver.jpg";
import ProjectImg7 from "../../assets/img/Wycliffe.jpg";
import ProjectImg8 from "../../assets/img/Azanwholesale.jpg";
import ProjectImg9 from "../../assets/img/Devzet.jpg";

export default function Projects() {

    const [show, setShow] = useState(false)

    const handleShowMore = () => {
        setShow(!show)
    }

    return (
        <Wrapper>
            <div className="whiteBg">
                <div className="container">
                    <HeaderInfo>
                        <div className="sectin-width">
                            <h2 className="title">Our Awesome Projects</h2>
                            <p className="subTitle">
                                Delve into our project showcase — a testament to blending creativity and innovation. Discover how we turn visions into impactful digital solutions across web, graphics, and marketing realms.
                            </p>
                        </div>
                    </HeaderInfo>
                    <div className="row textCenter g-5">
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 projectImg">
                            <ProjectBox
                                img={ProjectImg1}
                                title="Examtice"
                                text="Exams can be challenging and demanding. Examtice is here to guide and prepare you for your assessment through review (learning through practising) of past questions."
                                link="https://www.examtice.com/"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 projectImg">
                            <ProjectBox
                                img={ProjectImg2}
                                title="Extremeranks"
                                text="SEO stands for “Search Engine Optimization.” SEO is a way of making your business show up easier for relevant online searches. When you rank higher in SEO, your business gets more visibility."
                                link="https://extremeranks.com/"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 projectImg">
                            <ProjectBox
                                img={ProjectImg3}
                                title="Savershall"
                                text="Welcome to Savers Hall, where we are committed to delivering happiness to our valued customers. Our mission is to provide an exceptional shopping experience by offering a wide range of quality products and top-notch customer service."
                                link="https://savershall.com/"
                            />
                        </div>
                    </div>
                    <div className="row textCenter">
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 projectImg">
                            <ProjectBox
                                img={ProjectImg4}
                                title="Eurasiasupplies"
                                text="Eurasiasupplies.com is the best e-commerce site in Bangladesh which offers directly imported premium quality products from the UK, USA, Canada, Italy, Thailand, Malaysia, Singapore, Dubai, Australia, and many more countries."
                                link="https://eurasiasupplies.com/"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 projectImg">
                            <ProjectBox
                                img={ProjectImg5}
                                title="Mheec"
                                text="MHEEC consultancy offers high-quality and effective marketing services to the education establishment for recruiting their prospective students. It also maintains the highest level of integrity, transparency and fair practice with the students."
                                link="https://mheec.co.uk/"
                            />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 projectImg">
                            <ProjectBox
                                img={ProjectImg6}
                                title="Designwaver"
                                text="Welcome to Design Waver, your gateway to world-class graphic design services. We are a dynamic team of talented designers who are passionate about creating captivating visual experiences that make waves in the design industry."
                                link="https://www.designwaver.com/"
                            />
                        </div>

                    </div>
                    <div className={`${show ? "visible" : "hidden"}`}>
                        <div className="row textCenter">
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 projectImg">
                                <ProjectBox
                                    img={ProjectImg7}
                                    title="Wycliffe"
                                    text="William Cameron Townsend, who founded Wycliffe Bible Translators in 1942, believed everyone should have the Bible in their heart language so that the people can clearly understand the Word of God. Having caught this vision, Wycliffe Malaysia was incorporated in 1996 as a limited company to continue this important work of transforming lives through the understanding of God’s word."
                                    link="https://wycliffe.my/"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 projectImg">
                                <ProjectBox
                                    img={ProjectImg8}
                                    title="Azanwholesale"
                                    text="Demo Ecommerce Shop that would help you to sell your products from your site"
                                    link="http://azanwholesale.com/"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 projectImg">
                                <ProjectBox
                                    img={ProjectImg9}
                                    title="Devzet"
                                    text="There are a team of passionate individuals who go the extra mile to create wonderful experiences for your business."
                                    link="http://devzet.com"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row flexCenter">
                        <div style={{ margin: "50px 0", width: "200px" }}>
                            <FullButton title={show ? "Less More" : "Load More"} action={() => handleShowMore()} />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 100px;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  
  @media (max-width: 400px) {
    padding: 0;
  }
`;
